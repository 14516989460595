import { Seo } from "atoms";
import { graphql } from "gatsby";
import { Elevator } from "organisms";
import PropTypes from "prop-types";
import React from "react";

export const elevatorQuery = graphql`
  query ($uri: [String]) {
    craftAPI {
      entry(uri: $uri) {
        ... on CraftAPI_object_elevator_Entry {
          id
          title
          text
          redirectEntry {
            uri
            ... on CraftAPI_exhibit_exhibit_Entry {
              id
              title
              formattedTitleOverride
              subtitle
              startDate
              endDate
              fontType
              fontColor
            }
          }
          audioTrack: audioAsset {
            ... on CraftAPI_audio_Asset {
              url
            }
          }
          audioTranscription: multimediaAsset {
            ... on CraftAPI_multimedia_Asset {
              url
            }
          }
          elevatorRunTime
          elevatorAnimationControl
          elevatorIntroVideoFadeTiming
          elevatorVideoIntro {
            ... on CraftAPI_video_Asset {
              url
            }
          }
          elevatorItem {
            ... on CraftAPI_elevatorItem_item_BlockType {
              id
              image {
                ... on CraftAPI_image_Asset {
                  imageFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                  altText
                  url
                }
              }
              label
            }
          }
        }
      }
    }
  }
`;

const ElevatorTemplate = ({ data }) => {
  const {
    craftAPI: { entry },
  } = data;

  const items = entry.elevatorItem.map((item) => {
    return {
      image: item.image[0],
      alt: item.image?.[0]?.altText,
      label: item.label,
    };
  });
  const narrationTrack = {
    audioURL: entry.audioTrack?.[0]?.url,
    vttURL: entry.audioTranscription?.[0]?.url,
  };

  return (
    <main>
      <h1 className="visually-hidden">{entry.title}</h1>
      <Seo title={entry.title} />
      <Elevator
        animationStyle={entry.elevatorAnimationControl}
        entryData={entry.redirectEntry?.[0]}
        introVideo={entry.elevatorVideoIntro?.[0]?.url}
        keyControls={false}
        list={items}
        modalText={entry.text}
        narrationTrack={narrationTrack}
        redirectUri={entry.redirectEntry?.[0]?.uri}
        runtime={entry.elevatorRunTime}
        videoFadeTiming={entry.elevatorIntroVideoFadeTiming}
        preloadImages
      />
    </main>
  );
};

ElevatorTemplate.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ElevatorTemplate;
